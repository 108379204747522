<template>
    <div class="container">
        <div class="topTitle">
            <span>订单编号：{{pageInfo.ordersn}}</span>
            <span>下单时间：{{pageInfo.pay_time}}</span>
        </div>
        <div class="goodsinfo">商品信息</div>
        <el-table :data="tableData" border style="width: 100%" :span-method="objectSpanMethod" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="商品" width="300">
                <template slot-scope="scope">
                    <div class="goodslist">
                        <div class="productimg">
                            <img :src="scope.row.thumb" />
                        </div>
                        <div class="productinfo">
                            <p>{{scope.row.title}}</p>
                            <p>{{scope.row.attr}}</p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="price" label="价格" width="100"></el-table-column>
            <el-table-column prop="number" label="数量" width="100"></el-table-column>
            <el-table-column label="付款金额" width="200">
                <span>￥{{pageInfo.price}}</span>
                <span>（含运费￥{{pageInfo.express_price}}）</span>
            </el-table-column>
            <el-table-column label="物流方式" width="180">物流快递</el-table-column>
            <el-table-column prop="address" label="备注"></el-table-column>
        </el-table>
        <div class="goodsinfo">物流信息</div>
        <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
                <el-select placeholder="请选择物流公司" v-model="form.express">
                    <el-option v-for="item in expressList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-input placeholder="请输入快递单号" v-model="form.expresssn"></el-input>
                <el-button type="primary" @click="deliver">发货</el-button>
            </el-form-item>
        </el-form>
        <!-- <div class="goback">
            <el-button>返回</el-button>
        </div>-->
    </div>
</template>

<script>
import { request } from '@/common/request';
import { deal } from '@/common/main';
import bus from '@/common/bus';

export default {
    data() {
        return {
            id: 0,
            length: 0,
            pageInfo: {},
            expressList: [],
            form: {},
            tableData: [
            ],
            log: ''
        };
    },
    created() {
        this.getExpressList()
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to.query.id && to.query.id != this.id) {
                    this.id = to.query.id;
                    this.loadData();
                }
                //深度监听，同时也可监听到param参数变化
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/order/detail', { id: this.id }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data.list;
                    this.pageInfo.pay_time = deal.timestamp(this.pageInfo.pay_time, 'unix');
                    // 获取商品列表
                    ret.data.list.goods.map(item => {
                        if (item.list.state === 0) {
                            return _this.tableData.push(item.list)
                        }
                    })
                    this.length = this.tableData.length
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 获取所有物流公司
        getExpressList() {
            request.get('/common/express/list', { page: 1, pagesize: -1, state: 2 }).then(ret => {
                if (ret.code == 1) {
                    this.expressList = ret.data.list
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        // 选中发货
        handleSelectionChange(val) {
            let selectProductList = val;
            let ids = []
            ids = selectProductList.map(item =>
                item.id
            )
            this.form.id = ids.join(',')
            this.form.state = 3
        },
        // 发货
        deliver() {
            request.post('/order/deliver', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('发货成功');
                    bus.$emit('close_current_tags');
                    this.$router.push({ path: '/order-orderDetails', query: { 'id': this.id } })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        // 合并列
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 4 || columnIndex === 5 || columnIndex === 6) {
                if (rowIndex % this.length === 0) {
                    return {
                        rowspan: this.length,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.title {
    color: #999;
    font-size: 13px;
}
.topTitle {
    color: #666;
    font-size: 14px;

    span:nth-child(2) {
        padding: 0 15px;
    }
}
.goodsinfo {
    background: #f1f1f1;
    padding: 15px 10px;
    font-size: 14px;
    color: #333;
    margin-top: 10px;
}

.goodslist {
    display: flex;

    .productimg {
        width: 50px;
        height: 50px;
        margin-right: 5px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .productinfo {
        font-size: 13px;
        color: #666;
        line-height: 16px;

        p:nth-child(1) {
            max-height: 30px;
            min-height: 30px;
        }

        p:nth-child(2) {
            font-size: 12px;
            color: #999;
            margin-top: 3px;
        }
    }
}

.demo-form-inline {
    text-align: center;
    margin-top: 50px;
}

.goback {
    text-align: center;
    margin-top: 20px;
}
</style>